import React, { useState } from 'react';
import './arc.css';
import FunFact from '../FunFact';


const funfaceData = [
{ title: 'km Desired Range', factNumber: '200' },
{ title: 'sec 0 to 40 kmph', factNumber: '03' },
{ title: 'kmph Top Speed', factNumber: '105' },
{ title: 'KWh Energy Box', factNumber: '3.9' },
{ title: 'Ltrs Boot Storage', factNumber: '38' },
{ title: 'm Stopping Distance', factNumber: '26' },
];

const Arc = () => {
const [currentIndex, setCurrentIndex] = useState(0);

const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 1 : prevIndex - 1));
};

const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 1 ? 0 : prevIndex + 1));
};

return (
    <>
    <section id="Arc">
     <div className='arc_main_container'>
        <div className={`arc-bike-content-container ${currentIndex === 0 ? 'active' : ''}`}>
         <div className="arc-bike-image">
            <img src="./images/teaser_side_transparent.png" className='image' alt="Bike" />
         </div>
         <div className="arc-content">
            <div className="arc-text-wrapper">
             <h1 className="fade-in arc-h1">Arc</h1>
             <p className='arc-p'>Glide Through Life With Effortless Style and Freedom</p>
             
             <p className="fade-in arc-p"></p>
            </div>
         </div>
        </div>
        <div className={`arc-bike-content-container ${currentIndex === 1 ? 'active' : ''}`}>
         <div className="arc-bike-image">
            <img src="./images/teaser_front_transparent1.png" alt="Bike" style={{ width: "50%", height: "80%" }} />
         </div>
         <div className="arc-content">
            <div className="arc-text-wrapper">
             <h1 className="fade-in arc-h1">Designed For Efficiency And Style</h1>
             <p className='arc-p'>Perfect For Every Commuting And Beyond</p>
             <p className='arc-p'>Ride The Future With Our Scooters.</p>
             <p className="fade-in arc-p"></p>
            </div>
         </div>
        </div>
        <button className="prev arc-button" onClick={handlePrev}>❮</button>
        <button className="next arc-button" onClick={handleNext}>❯</button>
     </div>
     {/* <Spacing lg="30" md="10" /> */}
     <div className="container">
        <FunFact
         variant="cs-type1"
         title="./images/front_image1.png"
         subtitle="Your gateway to sustainable and efficient transportation, empowering your journey with green energy solutions."
         data={funfaceData}
        />
     </div>
     </section>
    </>
);
};

export default Arc;