import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link to='https://www.linkedin.com/company/syraenergy/' className="cs-center" target="_blank">
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
      <Link to='https://x.com/syraepl' className="cs-center" target="_blank">
      <Icon icon="hugeicons:new-twitter"  />
      </Link>
      <Link to='https://www.youtube.com/channel/UCRFcZYkznISdwok2qlrpVig' className="cs-center" target="_blank">
        <Icon icon="fa6-brands:youtube" />              
      </Link>
      <Link to='https://www.instagram.com/syra.energy/' className="cs-center" target="_blank">
        <Icon icon="hugeicons:instagram" />
      </Link>
      <Link to='https://www.facebook.com/profile.php?id=61556191414672' className="cs-center" target="_blank">
      <Icon icon="eva:facebook-outline" width="1.2em" height="1.2em" />
      </Link>
    </Div>
  )
}
