import React from 'react'
import { Icon } from '@iconify/react';
import GetInTouch from './GetInTouch';

export default function ContactInfoWidget({ withIcon,title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li style={{color:"white"}}>
        {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
            No.18, Vydehi Nagar, Kithaganur <br/>Bengaluru, Karnataka 560036
        </li>
      </ul>
      <div style={{marginTop:"7%"}}>
      <GetInTouch title='Get in Touch' underline={false}/>
      </div>
    </>
  )
}
