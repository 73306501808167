
import './style.css';

import Scroll from '../Scroll';

const PrivacyPolicy = () => {
return (
  <>
  <Scroll/>
    <div className="privacy-container">
     <header className='header'>
        {/* <div className="logo">
         <img style ={{width:'70%',height:'50%'}} src={logo} alt="Company Logo" />
        </div> */}
        <h5 style={{marginTop:"8%"}} > Privacy Policy</h5>
     </header>
     <div className="policies">
        <p>
         Syra Energy Private Limited hereinafter referred to as "SYRA" or "us" or "we" or "our" is conscious about the privacy of users hereinafter referred to as "you", "your", "yourself" who access our website located at <a href="https://syraenergy.com" target="_blank">www.syraenergy.com</a>.
        </p>
        <p>
         We have provided this Privacy Policy ("Policy") to acquaint you with the circumstances under which we collect your data from our website thereby process your data.</p> <p>We reserve the right to decline processing of your data if it violates the terms of this Policy, <a href="#">Terms of Use</a>, terms and conditions of Company and or applicable laws. By accessing and using this website, you hereby agree to the terms of this Policy. If you do not agree with the terms of this Policy, then please do not proceed with further use of this website. This Policy should be at all times read along with the Terms of Use of our Website. If you choose to visit our website, then your visit and any dispute is subject to this Policy and our Website's Terms of Use. Disputes arising from this Policy shall be governed by the laws of India.
        </p>
        <h6>PERSONAL DATA</h6>
        <p>
         Information security is an integral part of data privacy. While one cannot be certain that data transmission including over the Internet or any website can be secure from intrusion, we have in place industry standard procedural measures to help protect personal data from unauthorized access, use, disclosure, alteration or destruction in accordance with applicable laws. We will not be liable in any manner whatsoever for any illegal act of any third party which breaches the security of our website.
        </p>
        <p>
         In order for you to avail certain services on our website, we may seek your Personal Data to enable us to serve you better. We will process your Information for amongst other things, to provide customer service, support for technical issues or to otherwise support your relationship with us. Personal Data can be any sort of information that can be attributed to you. Personal Data that may be processed are name, address, e-mail address, telephone number, payment information, purchases, cancellations.
        </p>
        <p>
          You acknowledge that apart from your Personal Data, if you upload any content on our website containing information such as your Personal Data, the same may be available to the other users of our website. We will not be liable in any manner whatsoever for the said disclosure and dissemination of such Personal Data of yours to any third parties in any manner whatsoever.
        </p>
        <p>
          You can always choose not to provide your information and in such cases, if you do not share your information which is classified as mandatory by us, then you will not be able to avail certain part of our Services, features or content on our website.
        </p>
        <p>
          You can add or update your data on a regular basis. When you update your information, we will retain a copy of the prior version of your data for our records.
        </p>

        <h6>COOKIES</h6>
        <p>
          A cookie is a small text-based file that is stored on your computer, mobile or similar device and contains information about your navigation on the website.
        </p>
        <p>
          We use cookies to give you the full functionality of our website, to customize your user experience, perform analytics and improve our services. We may also process your usage history, Internet Protocol (IP) address, geolocation, device identification number, internet service provider, referring/exit pages, date/time stamp and number of clicks. We use them to analyze trends, administer our website, track a user's movement, and gather broad demographic information for aggregate use. Cookies are not linked to Personal Data.
        </p>

        <h6>DISCLOSURE OF PERSONAL DATA</h6>
        <p>
          You hereby agree that upon your access, use of our website, services, any purchases made by you through our website can lead to your information being processed by third parties such as including but not limited to, customer service companies, credit card, debit card processing companies. We will not be liable in any manner whatsoever to you for the information shared by you with credit card, debit card processing companies.
        </p>
        <p>
          Your credit-card, debit card details are transacted upon sites of payment gateways which are digitally under encryption, thereby providing the highest possible degree of care as per current technology. You are nevertheless advised that the internet is not 100% safe, and you should exercise discretion on using the same. We will not be liable to you for any illegal act of any third party which breaches the security of our website. We are not responsible for any breach of security or for any actions of any third parties that receive your data. Our website is linked to several other websites, and we are not responsible for their privacy policies or practices as it is beyond our control.
        </p>
        <p>
          However, we are legally obligated to disclose your data, in the event your data is sought for, by Government Authorities, Court of Law and or under applicable laws which includes but is not limited to exchanging information with other companies and organizations for fraud protection purposes.
        </p>
        <p>
          We may disclose your information if such disclosure is necessary in connection with any investigation or complaint received regarding including but not limited to your use of our website.
        </p>
        <p>
          You acknowledge and agree that we may preserve any communication by you made through our website or any Service, disclose such data if required to do so by law or if such preservation or disclosure is necessary to:
        </p>
        <ul>
          <li>comply with legal process;</li>
          <li>enforce this Policy;</li>
          <li>respond to claims that any such data violates the rights of others; or</li>
          <li>protect our, other Users' or Public's including but not limited to, rights, property, or personal safety.</li>
        </ul>

        <h6>FORCE MAJEURE</h6>
        <p>
          We shall not be held liable to you for any including but not limited to, loss, damage, or misuse of your data, if such loss, damage, or misuse is attributable to a Force Majeure Event (as defined below).
        </p>
        <p>
          "Force Majeure Event" means any event that is beyond our reasonable control which includes, without limitation, sabotage, fire, flood, explosion, acts of God, pandemic, epidemic, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption.
        </p>

        <h6>UPDATE TO PRIVACY POLICY</h6>
        <p>
          We may need to update our Privacy Policy and are not required to inform you of any changes made to Privacy Policy. We may update this privacy policy at any time, with or without giving prior notice. The latest version of our Privacy Policy is always available on our website and the same will be communicated to you via mail after any such updates.
        </p>

        <h6>PROMOTIONS</h6>
        <p>
          We are not liable to you in the event of collection and processing of your data by Third Parties through their advertisements, promotions on our website. These Third Parties may collect information about you and your online activities on our website through the use of various technologies in an effort to understand your interests and share promotions and advertisements with you that are customized to your interests.
        </p>
        <p>
          We explicitly state that we do not have access to, or control over, the collection of information by these third parties. The information gathering practices of these third parties are not covered by this Privacy Policy.
        </p>

        <h6>RIGHT TO WITHDRAW YOUR CONSENT</h6>
        <p>
          We provide Users with the option to stop receiving communications from us after you have created an Account with us. If you desire to remove your contact information from our lists and newsletters, kindly write to us at <a href="mailto:info@syraenergy.com">info@syraenergy.com</a> with "unsubscribe" as the subject.
        </p>

        <h6>QUERIES</h6>
        <p>
          You are free to connect with us via our e-mail <a href="mailto:info@syraenergy.com">info@syraenergy.com</a> for any concerns pertaining to this Policy with the subject line reading Privacy 'Policy'.
        </p>

        <h6>DATA USER CONSENT</h6>
        <p>You hereby convey your consent and permission to Syra
          
           energy, agents, and its Retail network to send from time to time communication about their products and services, offers and promotions, or any other communication through phone, SMS, written communication, email in compliance with applicable laws to you. You hereby agree to explicitly solicit communication from Syra Energy and its agents and its Retail network even if number / numbers of User is / are registered in the National Do Not Call Registr. You hereby agree that by accepting our Terms of Use and Privacy Policy on our website, we can use, collect, receive, possess, store, handle, process, disclose, transfer and use your data. You hereby agree that should you choose not to receive offers based on your previous orders and interests, you can inform us by sending an email to info@syraenergy.com. at any point in time to stop using your contact information to contact you or to send you any offers.</p>

<p>By using our website, products, content and services provided on our website, you agree and acknowledge that your Information collected through our website may be transferred across national boundaries and stored and processed in any the country around the world. You also acknowledge that in certain countries or with respect to certain activities, the collection, transfer, storage, and processing of your Information may be undertaken by trusted third party vendors or our agents.</p>
     </div>
    
    </div>
    </>
);
}

export default PrivacyPolicy;