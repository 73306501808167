import React, { useEffect } from 'react'

const Scroll = () => {
    const scroll =()=>{
        window.scrollTo({top:'0',behavior:'instant'});

    }
    useEffect(()=>{
        scroll()
    },[])
  return (
    <></>
  )
}

export default Scroll;