import React, { useState } from 'react';
import parse from 'html-react-parser';
import './hero.scss';
import Button from '../Button';
import { Icon } from '@iconify/react';
import Div from '../Div';

import { HashLink } from 'react-router-hash-link';

export default function Hero({
  title,
  subtitle,
  subtitle2,
  btnText,
  btnLink,
  scrollDownId,
  socialLinksHeading,
  // heroSocialLinks,
  bgImageUrl,
}) {
  
  return (
    <section id="hero">
    <Div
      className="cs-hero cs-style1 cs-bg cs-fixed_bg background-image cs-shape_wrap_1"
      // style={{ backgroundImage: `url(${bgImageUrl})`,}}
     >
      {/* <Div className="cs-shape_1" /> */}
      {/* <Div className="cs-shape_1" /> */}
      {/* <Div className="cs-shape_1" /> */}
      <Div className="container">
        <Div className="cs-hero_text">
          <h1 className="cs-hero_title">{parse(title)}</h1>
          <Div className="cs-hero_info">
            <Div className="expolre_button">
              {/* <Button btnText={btnText} /> */}
              <HashLink to={btnLink} >{btnText}</HashLink> <Icon icon="bi:arrow-right" />
            </Div>
            <Div>
              <Div style={{translate:"20px -20px 0"}} className="cs-hero_subtitle">{subtitle}</Div>
              <Div style={{translate:"20px -25px 0"}} className='cs-hero_subtitle2'>{subtitle2}</Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
    </section>
  );
}
