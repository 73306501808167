
import Scroll from '../Scroll';
import './Terms.css';


const TermsOfUse = () => {
return (
  <>
  <Scroll/>
    <div className="terms-container">
     <header className="term_header">
        {/* <div className="logo">
         <img style ={{width:'70%',height:'50%'}} src={logo} alt="Company Logo" />
        </div> */}
        <h5 style={{marginTop:"8%"}} >Terms Of Use</h5>
     </header>
     <div className="content">
        <h6>TERMS OF USE</h6>
        <p>These terms and conditions of use ("Terms of Use") of the Website (as defined below) between Syra Energy Private Limited (hereinafter referred to as "Syra Energy") and the users/ registrants of the Website ("You" or "Your" or "Yourself" or "User") describe the terms on which Syra Energy offers You access to the Website and the Services (as defined below) through the Website.
Please read the terms of use carefully before using or registering on the website or accessing any material, information, or services through the website. Your use of the website or the services provided by the website shall signify your acceptance of the terms of use and your agreement to be legally bound by the same.

Your use of the website implies that you agree with the terms of use.</p>
     <h6>General</h6>
     <p>1.1 <a href="https://syraenergy.com" target="_blank">www.syraenergy.com</a> is an Internet based portal owned and operated by Syra Energy, a company incorporated under the laws of India. Use of the Website is offered to You conditioned on acceptance of all the terms, conditions and notices contained in these Terms, along with any amendments made by Syra Energy at its sole discretion and posted on the Website, including by way of imposing an additional charge for access to or use of a Service</p>
    <p>1.2 Syra Energy shall not be required to notify You, whether as a registered user or not, of any changes made to the Terms of Use. The revised Terms of Use shall be made available on the Website. Your use of the Website and the Services is subject to the most current version of the Terms of Use made available on the Website at the time of such use. You are requested to regularly visit the home page <a href="https://syraenergy.com" target="_blank">www.syraenergy.com</a> to view the most current Terms of Use.</p>
    <p>1.3 By (i) using this Website or any facility or Service provided by this Website in any way; or (ii) merely browsing the Website, You agree that You have read, understood and agreed to be bound by these Terms of Use and the Website's Privacy Policy available at the homepage. <a href="https://syraenergy.com" target="_blank">www.syraenergy.com</a></p> 
      
      
      <div>
        <h6>Services</h6>
        <p>
        The Website is a platform that facilitates the online sale and purchase of electric vehicles and services offered by Syra Energy ("Services"). The purchase of products and services on the Website shall be governed by the Terms of Offer for Sale ("Terms of Offer for Sale").

        </p>
      </div>
      
      <div>
        <h6>Eligibility</h6>
        <p>
        3.1 The Services are not available to minors under the age of eighteen (18) or to any Users suspended or removed from the Syra Energy system by Syra Energy for any reason whatsoever. If You do not conform to the above qualification, you shall not be permitted to avail of the Services or use the Website. You represent that You are of legal age and eligible in all respects to form a binding contract and are not a person barred from receiving services under the laws as applicable in India. Notwithstanding the foregoing, if You are below the age of eighteen (18) years, you may avail the Services provided by Syra Energy through Your legal guardian in accordance with the applicable laws.
        <p>3.2 Syra Energy reserves the right to refuse access to use the Services offered at the Website to new Users or to terminate access granted to existing Users at any time without according any reasons for doing so.</p>
        <p>3.3 You shall not have more than one active Account on the Website. Additionally, you are prohibited from selling, trading, or otherwise transferring Your Account to another party.</p>
          
        </p>
      </div>
      <div>
        <h6>User Account, Password, and Security</h6>
        <p>
         4.1 Syra Energy makes the Services available to you through the Website only if you have provided Syra Energy certain required User information and created an account ("Account") through Syra Energy ID and password (collectively, the "Account Information"). The Services may also be subject to procedures for use of the Website, Terms of Offer for Sale, uploaded guidelines, rules, additional terms of service, or other disclaimer & notices, if any ("Additional Terms"). If there is any conflict between the Terms of Use and the Additional Terms, the Additional Terms shall take precedence in relation to that service.
        </p>
        <p>
          4.2 The Website requires you to register as a User by creating an Account to avail of the Services provided by the Website. You will be responsible for maintaining the confidentiality of the Account Information and are fully responsible for all activities that occur under your Account. You agree to (a) immediately notify Syra Energy of any unauthorized use of your Account Information or any other breach of security, and (b) ensure that you exit from your Account at the end of each session. Syra Energy cannot and will not be liable for any loss or damage arising from your failure to comply with this. You may be held liable for losses incurred by Syra Energy or any other user of or visitor to the Website due to authorized or unauthorized use of your Account because of your failure in keeping your Account Information secure and confidential.
        </p>
        <p>
          4.3 The Website also allows restricted access to the Services for unregistered Users.
        </p>
        <p>
          4.4 You shall ensure that the Account Information provided by you in the Website's registration form is complete, accurate, and up to date. Use of another user's Account Information for availing the Services is expressly prohibited.
        </p>
        <p>
         4.5 If you provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Syra Energy has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Syra Energy has the right to suspend or terminate your Account and refuse any and all current or future use of the Website (or any portion thereof) and further reserves its right to proceed against you as per law in case of any illegal or malicious act in whatsoever manner.
        </p>
      </div>

      <div>
        <h6>Pricing Information</h6>
        <p>
          5.1 Syraenergy strives to provide you with the best prices possible on products and services you buy or avail of from the Website. The pricing details for purchase of products and services from the Website are detailed under the Terms of Offer for Sale.
        </p>
        <p>
         5.2  You agree to provide correct and accurate credit/debit card details to the approved payment gateway for availing Services on the Website. You shall not use the credit/debit card, which is not lawfully owned by you, i.e., in any transaction, you must use your own credit/debit card. The information provided by you will not be utilized or shared with any third party unless required in relation to fraud verifications or by law, regulation, or court order. You will be solely responsible for the security and confidentiality of your credit/debit card details. Syra Energy expressly disclaims all liabilities that may arise as a consequence of any unauthorized use of your credit/debit card.
        </p>
        <p>
         5.3 Syra Energy shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any transaction, on account of you/cardholder having exceeded the present limit mutually agreed by Syra Energy with our acquiring bank from time to time.
        </p>
        <p>
          5.4 The price of our Electric Scooter Syra Energy is subject to change without prior notice and the price prevailing at the time of invoice shall apply, irrespective of the price at the time of initial pre-booking.
        </p>
      </div>

      <div>
        <h6>User Obligations & Conduct</h6>
        <p>
          6.1 Subject to compliance with the Terms of Use, Syra Energy grants you a non-exclusive, limited privilege to access and use this Website and the Services provided therein.
        </p>
        <p>
         6.2 You agree to use the Services, Website and the materials provided therein only for purposes that are permitted by: (a) the Terms of Use; and (b) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.
        </p>
        <p>
         6.3 You agree to adhere to all limitations on dissemination, use and reproduction of any materials (such as the product catalogues) that you access on the Website in accordance with Section 7.
        </p>
        <p>
         6.4 You agree not to access (or attempt to access) the Website and the materials or Services by any means other than through the interface that is provided by Syra Energy. You shall not use any deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or Content (as defined below), or in any way reproduce or circumvent the navigational structure or presentation of the Website, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Website.
        </p>
        <p>
         6.5 You acknowledge and agree that by accessing or using the Website or Services, you may be exposed to content from other users that you may consider offensive, indecent, or otherwise objectionable. Syra Energy disclaims all liabilities arising in relation to such offensive content on the Website. Further, you may report such offensive content in the manner prescribed under Section 15 herein.
        </p>
        <p>
         6.6 If the Website allows you to post and upload any material on the Website, you hereby undertake to ensure that such material is not offensive and in accordance with applicable laws.
        </p>
        <p>
        6.7  You agree that Syra Energy may, at any time, modify or discontinue all or part of the Website, charge, modify or waive fees required to use the Website, or offer opportunities to some or all Website Users.
        </p>
        <p>
         6.8 You are also under an obligation to use this Website for reasonable and lawful purposes only and shall not indulge in any activity that is not envisaged through the Website.
        </p>
      </div>

      <div>
        <h6>Use of Materials</h6>
        <p>
         7.1 Except as expressly indicated to the contrary in any applicable Additional Terms, Syra Energy hereby grants you a non-exclusive, freely revocable (upon notice from Syra Energy), non-transferable access to view, download and print product catalogues or any other materials available on the Website.
        </p>
        <p>
         7.2 The rights granted to you in the product catalogues, or any other materials as specified above are not applicable to the design, layout or look and feel of the Website. Such elements of the Website are protected by intellectual property rights and may not be copied or imitated in whole or in part. The product catalogues or any other materials available on the Website cannot be copied or retransmitted unless expressly permitted by Syra Energy.
        </p>
        <p>
         7.3 Any purchase of the electric vehicle or Services from the Website will be strictly for personal use of the User. The User hereby expressly agrees that any product or Services purchased by the User will not be sold, resold, bartered or in any way used for any commercial purposes or for profit. The User hereby acknowledges that the Services or products purchased is not transferrable to any third party for profit. In case of any breach of the aforesaid conditions you would be liable to be proceeded against in accordance with the appropriate law at your own risk and consequences and you accept the liability thereof.
        </p>
      </div>

      <div>
        <h6>Intellectual Property Rights</h6>
        <p>
          8.1 The Website and the processes, and their selection and arrangement, including but not limited to all text, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code (collectively, the "Content") on the Website is owned and controlled by Syra Energy and the design, structure, selection, coordination, expression, look and feel and arrangement of such Content is protected by copyright, patent and trademark laws, and various other intellectual property rights.
        </p>
        <p>
        8.2  The trademarks, logos and service marks displayed on the Website ("Marks") are the property of Syra Energy or their vendors or respective third parties.You are not permitted to use the Marks without the prior consent of Syra Energy, the vendor or the third party that may own the Marks.
        </p>
      
        <p>8.3 Except as expressly provided herein, you acknowledge and agree that You shall not copy, republish, post, display, translate, transmit, reproduce, or distribute any Content through any medium without obtaining the necessary authorization from Syra Energy or thirty party owners of such Content.</p>
        <p>8.4 In case of any breach of the aforesaid conditions You would be liable to proceeded against in accordance with the appropriate law at your own risk and consequences and You accept the liability thereof.</p>
      </div>
      <div>
        <h5>Third Party Content</h5>
        <p>
          The Website makes available general third-party information such as, product catalogues, lists of authorized dealers, reports on news, entertainment, technology and features, advertisements including videos, images and photographs of the products and other data from external sources ("Third Party Content"). Similar Third-Party Content would also be available to You on the email received by You from Syra Energy. The provision of Third-Party Content is for general informational purposes only. You acknowledge that the Third-Party Content provided to You is obtained from sources believed to be reliable. Syra Energy does not provide any guarantee with respect to any the Third-Party Content and Syra Energy shall not be held liable for any loss suffered by You based on Your reliance on or use of such data.
        </p>
      </div>

      <div>
        <h6>Disclaimer of Warranties & Liability</h6>
        <p>
          You expressly understand and agree that, to the maximum extent permitted by applicable law:
        </p>
        <p>
          10.1 the website, services and other materials are provided by Syra Energy on an "AS IS" Basis without warranty of any kind, express, implied, statutory, or otherwise, including the implied warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Without limiting the foregoing, Syra Energy makes no warranty that (I) the website or the services will meet your requirements or your use of the website or the services will be uninterrupted, timely, secure or error-free; (ii) the results that may be obtained from the use of the website, services or materials will be effective, accurate or reliable; (iii) the quality of the website, services or other materials will meet your expectations; or that (iv) any errors or defects in the website, services or other materials will be corrected . No advice or information, whether oral or written, obtained by you from Syra Energy or through or from use of the services shall create any warranty not expressly stated in the terms of use.
        </p>
        <p>
          10.2 to the maximum extent permitted by applicable law, Syra Energy will have no liability related to user content arising under intellectual property rights, libel, privacy, publicity, obscenity, or other laws. Syra Energy also disclaims all liability with respect to the misuse, loss, modification, or unavailability of any user content.
        </p>
        <p>
          10.3 Syra Energy will not be liable for any loss that you may incur as a consequence of unauthorized use of your account or account information in connection with the website or any services or materials, either with or without your knowledge. Syra Energy has endeavored to ensure that all the information on the website is correct, but Syra Energy neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, product, or service. Syra Energy shall not be responsible for the delay or inability to use the website or related functionalities, the provision of or failure to provide functionalities, or for any information, software, products, functionalities, and related graphics obtained through the website, or otherwise arising out of the use of the website, whether based on contract, tort, negligence, strict liability or otherwise. Further, Syra Energy shall not be held responsible for non-availability of the website during periodic maintenance operations or any unplanned suspension of access to the website that may occur due to technical reasons or for any reason beyond Syra Energy's control. The user understands and agrees that any material or data downloaded or otherwise obtained through the website is done entirely at their own discretion and risk and they will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material or data. Syra Energy accepts no liability for any errors or omissions, with respect to any information provided to you whether on behalf of itself or third parties.
        </p>
      </div>

      <div>
        <h6>Indemnification and Limitation of Liability</h6>
        <p>
          11.1 You agree to indemnify, defend and hold harmless Syra Energy including but not limited to its affiliate vendors, agents and employees from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Syra Energy that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by You pursuant to these Terms of Use. Further, you agree to hold Syra Energy harmless against any claims made by any third party due to, or arising out of, or in connection with, your use of the Website, any claim that Your material caused damage to a third party, your violation of the Terms of Use, or Your violation of any rights of another, including any intellectual property rights.
        </p>
        <p>
          11.2 Notwithstanding anything to contrary, Syra Energy's entire liability to You under this Terms of Use or otherwise shall be the refund of the money charged from You for any specific voucher or product or service, under which the unlikely liability arises.
        </p>
        <p>
          11.3 In no event shall Syra Energy , its officers, directors, employees, partners or suppliers be liable to You, the vendor or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable or whether or not Syra Energy has been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortious action, or any other claim arising out of or in connection with Your use of or access to the Website, Services or materials.
        </p>
        <p>
          11.4 The limitations and exclusions in this section apply to the maximum extent permitted by applicable law.
        </p>
      </div>

      <div>
        <h6>Violation of the Terms of Use</h6>
        <p>
          12.1 You agree that Syra Energy may, in its sole discretion and without prior notice, terminate Your access to the Website and block Your future access to the Website if Syra Energy determines that You have violated these Terms of Use or Additional Terms. You also agree that any violation by You of these Terms of Use will constitute an unlawful and unfair business practice, and will cause irreparable harm to Syra Energy, for which monetary damages would be inadequate, and You consent to Syra Energy obtaining any injunctive or equitable relief that Syra Energy deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies Syra Energy may have at law or in equity.
        </p>
        <p>
          12.2 You agree that Syra Energy may, in its sole discretion, and without prior notice, terminate Your access to the Website, for cause, which includes (but is not limited to): (1) requests by law enforcement or other government agencies; (2) a request by You (self-initiated account deletions); (3) discontinuance or material modification of the Website or any service offered on or through the Website; (4) unexpected technical issues or problems; or (5) breach of Terms of Use, the Privacy Policy or any other terms, conditions, or policies that may be applicable to You from time to time.
        </p>
        <p>
          12.3 If Syra Energy does take any suitable legal action against You as a result of Your violation of these Terms of Use, Syra Energy will be entitled to recover from You, and You agree to pay, all reasonable attorneys' fees and costs of such action, in addition to any other relief granted to Syra Energy.
        </p>
      </div>

      <div>
        <h6>Termination</h6>
        <p>
          13.1 The Terms of Use will continue to apply until terminated by either You or Syra Energy as set forth below. If You want to terminate Your agreement with Syra Energy, you may do so by (i) not accessing the Website; or (ii) closing Your accounts for all the Services that You use, where Syra Energy has made this option available to You.
        </p>
        <p>
          13.2 Syra Energy may, at any time, with or without notice, terminate the Terms of Use (or portion thereof, such as any individual Additional Terms) with You if: You breach any of the provisions of the Terms of Use, the Privacy Policy or any other terms, conditions, or policies that may be applicable to You from time to time (or have acted in a manner that clearly shows that You do not intend to, or are unable to, comply with the same); Syra Energy is required to do so by law (for example, where the provision of the Services to You is, or becomes, unlawful); The provision of the Services to You by Syra Energy is, in Syra Energy’s opinion, no longer commercially viable; Syra Energy has elected to discontinue, with or without reason, access to the Website, the Services (or any part thereof); or
        </p>
        
        <p>13.3 Syra Energy may also terminate or suspend all or a portion of Your account or access to the Services with or without reason. Except as may be set forth in any Additional Terms applicable to a particular Service, termination of Your Account may include: (i) removal of access to all offerings within the Website or with respect to the Services; (ii) deletion of Your materials and Account Information, including Your personal information, log-in ID and password, and all related information, files and materials associated with or inside Your Account (or any part thereof); and (iii) barring of further use of the Services.</p>
        <p>the Services.
        13.4 You agree that all terminations shall be made in Syra Energy â€˜s sole discretion and that Syra Energy shall not be liable to You or any third party for any termination of Your Account (and accompanying deletion of Your Account Information), or Your access to the Website and Services.</p>
        <p>13.5 Notwithstanding the foregoing, these Terms of Use will survive indefinitely unless and until Syra Energy chooses to terminate them.

</p>
    </div>
    <h6>General Provisions</h6>
    <p>
      <strong>NOTICE:</strong> ALL NOTICES OF SYRA ENERGY WILL BE SERVED BY EMAIL OR BY GENERAL NOTIFICATION ON THE WEBSITE. ANY NOTICE PROVIDED TO SYRA ENERGY PURSUANT TO THE TERMS OF USE SHOULD BE SENT TO SYRA ENERGY CUSTOMER SUPPORT.
    </p>
    <p>
      <strong>ASSIGNMENT:</strong> YOU CANNOT ASSIGN OR OTHERWISE TRANSFER THE TERMS OF USE, OR ANY RIGHTS GRANTED HEREUNDER TO ANY THIRD PARTY. SYRA ENERGY’S RIGHTS UNDER THE TERMS OF USE ARE FREELY TRANSFERABLE BY SYRA ENERGY TO ANY THIRD PARTIES WITHOUT THE REQUIREMENT OF SEEKING YOUR CONSENT.
    </p>
    <p>
      <strong>SEVERABILITY:</strong> IF, FOR ANY REASON, A COURT OF COMPETENT JURISDICTION FINDS ANY PROVISION OF THE TERM OF USE, OR PORTION THEREOF, TO BE UNENFORCEABLE, THAT PROVISION SHALL BE ENFORCED TO THE MAXIMUM EXTENT PERMISSIBLE SO AS TO GIVE EFFECT TO THE INTENT OF THE PARTIES AS REFLECTED BY THAT PROVISION, AND THE REMAINDER OF THE TERMS OF USE SHALL CONTINUE IN FULL FORCE AND EFFECT.
    </p>

    <h6>Feedback and Information</h6>
    <p>
      Any feedback You provide to this Website shall be deemed to be non-confidential. Syra Energy shall be free to use such information on an unrestricted basis. Further, by submitting the feedback, You represent and warrant that (i) Your feedback does not contain confidential or proprietary information of You or of third parties; (ii) Syra Energy is not under any obligation of confidentiality, express or implied, with respect to the feedback; (iii) Syra Energy may have something similar to the feedback already under consideration or in development; and (iv) You are not entitled to any compensation or reimbursement of any kind from Syra Energy for the feedback under any circumstances.
    </p>

    <h6>Return Policy</h6>
    <p>
      If a customer opts for a cancellation of pre-booking, the pre-booking amount is fully refundable.
    </p>
    <p>
      All eligible refunds shall be processed by us within a period of 45 working days.
    </p>
    <p>
      Please note that the Customers will be eligible for refunds only for the pre-booking amount paid by the Customers. The Customers shall not be eligible for any refunds on payment by the Customer of the complete purchase amount towards the vehicle.
    </p>

    <h6>Shipping Policy</h6>
    <p>
      The Product deliveries shall happen from authorized sales centers only.
    </p>
    <p>
      The date of delivery might vary for the consumers as per the expansion plans of SEPL and sequence of bookings.
    </p>
   
     </div>
     </div>
     </>  
    
    
);
}

export default TermsOfUse;