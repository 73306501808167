import React from 'react';
import './TextWidget.css';
import Div from '../Div';

export default function TextWidget({logoSrc, logoAlt, text}) {
  return (
    <Div className="cs-text_widget footor_logo">
      
      <img src={logoSrc}  alt={logoAlt} style={{width:"90px", height:"70px", translate:'70px 0 0'}} />
      <p style={{color:'white'}}>{text}</p>
    </Div>
  )
}
