import React from 'react';
import Div from '../Div';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import Newsletter from '../Widget/Newsletter';
import SocialWidget from '../Widget/SocialWidget';
import TextWidget from '../Widget/TextWidget';
import './footer.scss';
const copyrightLinks = [
  {
    title: 'Terms of Use',
    href: 'terms-of-use',
  },
  {
    title: 'Privacy Policy',
    href: 'privacy-policy',
  },
];

const serviceMenu = [
  {
    title: 'Arc',
    href: '/#Arc',
  },
  {
    title: 'Technology',
    href: '/#tech',
  },
  // {
  //   title: 'Sign Up',
  //   href: '',
  // },
  // {
  //   title: "FAQ'S",
  //   href: '/#faq',
  // },
];

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/main-logo-white.png"
                  logoAlt="Logo"
                  text="SYRA Energy welcomes you! Experience The Ride Of Future"/>
                  <SocialWidget/>
              </Div>
            </Div>
            <Div className="col-lg-2 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading="SYRA" />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title="Registered Office Address" />
              </Div>
            </Div>
            <Div className="col-lg-4 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter
                  title="Subscribe"
                  subtitle="Subscribe now to receive the latest updates, special offers, and exclusive content straight to your inbox."
                  placeholder="Your email here"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright" style={{color:'white',fontSize:'9pt'}}>SYRA © 2024</Div>
          </Div>
          <Div className="cs-bottom_footer_right" style={{color:'white',fontSize:'9pt'}}>
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
